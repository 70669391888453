import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { themes } from "../../themes/themes";
import StandardButton from "../Button/Button";

export default function AvailableLicensesComponent({value, navigate}:{value?: number, navigate: ReturnType<typeof useNavigate>}){

    const {white, shadow} = themes;

    const Licenses = styled.div`
        display: flex; 
        flex-direction: column ;
        background-color: ${white};
        border-radius: 18px; 
        padding: 16px; 
        margin-bottom: 24px; 
        box-shadow: 0px 0px 25px ${shadow};
        overflow: hidden;
        gap: 10px;
        max-width: 300px;

        @media(max-width: ${themes.mobileDimension}){
            max-width: 100%;
        }
    `;

    const AvailableLicenses = styled.p`
        color: ${themes.darkGray};
        font-size: 0.875rem;
        font-family: ${themes.primaryFont};
        font-weight: 500;
        line-height: 1.5rem;
        word-wrap: break-word;
    `;

    const LicensesButton = styled(StandardButton)`
        width: 100%;
    `;

    const LicensesNumber = styled.p`
        color: ${themes.darkGray};
        font-size: 1.875rem;
        font-family: ${themes.primaryFont};
        font-weight: 500;
        line-height: 1.5rem;
        word-wrap: break-word; 
    `;
    
    return (
        <>
            <Licenses>
                    <LicensesNumber>
                            {value}
                    </LicensesNumber>
                    <AvailableLicenses>Licenças Disponíveis</AvailableLicenses>
                    <LicensesButton
                        onClick={() => navigate("")}
                        buttonType="primary"
                        text="Obter mais licenças"
                    />
            </Licenses>
        </>
                         
        )
}