import styled from "styled-components";
import { themes } from "../../themes/themes";

const { purple, white } = themes;

export const BackGround = styled.div`
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-color: ${white};
    z-index: 1;
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const DivInnerContainer = styled.div`
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    z-index: 11;
    margin-left: 12px;
    margin-right: 12px;
`;

export const DivImgsFooter = styled.div`
    z-index: 9;
`

export const Ellipse2 = styled.img`
    position: absolute;
    bottom: 0;
    left: 130px;
    opacity: 0.6;
    mix-blend-mode: screen;

    @media (max-width: ${themes.mobileDimension}){
        left: 0;
    }
`

export const Ellipse6 = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.6;
    mix-blend-mode: screen;
`

export const Ellipse7 = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.6;
    mix-blend-mode: screen;
`

export const Ellipse8 = styled.img`
    position: absolute;
    top: 0;
    right: 25px;
    mix-blend-mode: screen;
    opacity: 0.6;
`