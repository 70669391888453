import { useQuery } from "react-query"
import AxiosConfig from "../../configs/AxiosConfig"

const fetchGetUserDetails = () => {
    const response = AxiosConfig
        .getAxiosInstance()
        .get("/api/v1/users/licensed");

    return response;
}

export const useGetUserDetails = () => {
    const query = useQuery({
        queryKey: ['personal-data'],
        queryFn: fetchGetUserDetails,
        refetchOnWindowFocus: false
    })
    return query;
}