import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { themes } from "../../themes/themes";

const { white, purpleNavBar, dashBoardFont, bgOptionGradient, blueDashBoard } = themes;

interface Props {
    isSelected?: boolean;
    PopUpExitIsClicked?: boolean;
    showNavBarMobile?: boolean;
    fadeOut?: boolean;
}

export const MainContainer = styled.div<Props>`
    display: flex;
    z-index: 11;
`;

export const NavBar = styled.div<Props>`
    min-height: 100%;
    min-width: 300px;
    background-color: ${white};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    vertical-align: auto;
    transition: left 0.5s ease;

    @media (max-width: 780px){
        left: ${props => props.showNavBarMobile ? '0' : '-300px'};
        z-index: 61;
        position: fixed;
        background-color: rgb(226, 227, 243);
    }
`;

export const LogoHiveBImgStudio = styled.img`
    justify-self: center;
    margin-top: 2rem;
    width: 125px;
    height: 55px;
`;

export const OptionsList = styled.ul`
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    flex: 1 0 auto;
`;

export const Option = styled.div<Props>`
    width: 250px;
    display: flex;
    background: ${props => props.isSelected ? bgOptionGradient : "transparent"};
    border-radius: 22.50px;
    position: relative;
    align-items: center;
    &:hover{
        cursor: pointer;
        opacity: 0.9;
    }
`;

export const NavBarOption = styled.div<Props>`
    width: 250px;
    display: flex;
    position: relative;
    border-radius: 22.50px;
    background: ${props => props.isSelected ? bgOptionGradient : "transparent"};
    &:hover{
        cursor: pointer;
        opacity: 0.9;
    }
`;

export const Text = styled.p`
    color: ${purpleNavBar};
    font-size: 0.875rem;
    font-family: ${dashBoardFont};
    font-weight: 500;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: 16px;
`;

export const OptionImg = styled.img`
    margin-left: 1.2rem;
    display: flex;
`;

export const TopEllipse = styled.img`
    position: absolute;
    top: 0;
    left: 0;
`;

export const TopEllipseVoid = styled.img`
    position: absolute;
    top: 0;
    left: 0;
`;

export const LowerEllipse = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
`;

export const LowerEllipseVoid = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
`;

export const BgBlur = styled.div`
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    min-width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 60;

    @media (min-width: ${themes.tabletDimension}){
        display: none;
    }
    @keyframes fadeIn {
        0% { opacity: 0 }
        100% { opacity: 1 }
    };
    animation: fadeIn 0.5s ease;
`
export const BgShadow = styled.div`
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    min-width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 61;

    @keyframes fadeIn {
        0% { opacity: 0 }
        100% { opacity: 1 }
    };
    animation: fadeIn 0.5s ease;
`

export const Div = styled.div`
    display: flex;
    flex-direction: column;
`

export const CloseX = styled(MdClose)`
    position: absolute;
    top: 20px;
    right: 20px;
    height: 30px;
    width: 30px;

    @media (min-width: ${themes.tabletDimension}){
        left: -300px;
    }
`

export const ContainerOutlet = styled.div`
    margin-left: 300px;
    flex: 1;
    display: flex;
    width: calc(100vw - 300px);

    @media (max-width: ${themes.tabletDimension}){
        margin-left: 0px;
        width: 100%;
    }
`