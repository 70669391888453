import { useFormik } from 'formik';
import * as yup from 'yup';

import LoginCard from '../../components/LoginCard';
import InputText from '../../components/TextInput/TextInput';
import StandardButton from '../../components/Button/Button';
import { useResetPassword } from '../../hooks/userService/useResetPassword';
import { 
    InnerCard, 
    TitleCreateNewPassword, 
    MainContainer, 
    TextCreateNewPassword, 
    DivInput, 
    Buttons, 
    AlertInstructions
 } from './ResetPasswordPage.styles';

function ResetPasswordPage() {
    const { mutate: resetPassword, isLoading, error, isSuccess } = useResetPassword();

    const validationSchema = yup.object().shape({
        newPassword: yup
          .string()
          .min(8, 'Sua senha deve possuir mais do que 8 caracteres')
          .required('Digite sua senha'),
        changepassword: yup.string().when("newPassword", {
          is: (val: any) => (val && val.length > 0 ? true : false),
          then: yup.string().oneOf(
            [yup.ref("newPassword")],
            "As senhas devem ser iguais"
          )
        })
    });

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            changepassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            resetPassword(formik.values.newPassword);
        },
    });

    const verifyPasswordBeforeSubmit = () => {
        if(formik.values.newPassword !== formik.values.changepassword){
            return true;
        }else if(formik.values.newPassword === "" && formik.values.changepassword === ""){
            return true;
        }else{
            return false;
        }
    }

    return (
      <MainContainer>
            <TitleCreateNewPassword>
                Criar nova senha
            </TitleCreateNewPassword>
            <LoginCard>
                <InnerCard>
                    <form onSubmit={formik.handleSubmit}>
                        <TextCreateNewPassword>
                            Criar uma nova senha para sua conta
                        </TextCreateNewPassword>
                        {!isLoading && !!error && (
                            <AlertInstructions severity="error">{error}</AlertInstructions>
                        )}
                        {!isLoading && isSuccess && (
                            <AlertInstructions severity="success">Senha alterada com Sucesso!</AlertInstructions>
                        )}
                        <DivInput>
                            <InputText
                                inputId="newPassword"
                                placeholder="Nova senha"
                                type="password"
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                errorText={formik.errors.newPassword || ""}
                            />
                            <InputText
                                inputId="changepassword"
                                placeholder="Confirmação de senha"
                                type="password"
                                value={formik.values.changepassword}
                                onChange={formik.handleChange}
                                error={formik.touched.changepassword && Boolean(formik.errors.changepassword)}
                                errorText={formik.errors.changepassword || ""}
                            />
                        </DivInput>
                        <Buttons>
                            <StandardButton
                                text='Criar'
                                type='submit'
                                loading={isLoading}
                                onClick={formik.handleSubmit}
                                disabled={verifyPasswordBeforeSubmit()}
                            />
                        </Buttons>
                    </form>
                </InnerCard>
            </LoginCard>
      </MainContainer>
    );
}
  
export default ResetPasswordPage;