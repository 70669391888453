import ComponentSkeleton from "../../components/Skeleton-Loading/steletonLoading";
import TextInput from "../../components/TextInput/TextInput";
import { useGetUserDetails } from "../../hooks/userService/useGetUserDetails";
import { ButtonResetPassword, Content, DivButton, DivInput, DivResetPassword, ErrorMessage, ErrorMessageContainer, InnerContainer, MainContainer, PersonalDataContent, PersonalDataTitle, RetryButton, SkeletonDiv, Title } from "./SettingsPage.styles";
import InputData from "./components/InputData";
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputText from '../../components/TextInput/TextInput';
import { useChangePassword } from "../../hooks/userService/useChangePassword";
import { useEffect } from "react";


export default function SettingsPage(){

    const { data: personalData, isLoading, isError, refetch } = useGetUserDetails();

    const { mutate: resetPassword, isLoading: isLoadingChangePassword, error, isSuccess } = useChangePassword();
    
    const validationSchema = yup.object().shape({
        currentPassword: yup
        .string()
        .required('Digite sua senha atual'),
        newPassword: yup
          .string()
          .min(8, 'Sua senha deve possuir mais do que 8 caracteres')
          .required('Digite sua senha'),
        changePassword: yup.string().when("newPassword", {
          is: (val: any) => (val && val.length > 0 ? true : false),
          then: yup.string().oneOf(
            [yup.ref("newPassword")],
            "As senhas devem ser iguais"
          )
        }).required('Campo obrigatório')
    });

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            changePassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            resetPassword({currentPassword: formik.values.currentPassword, newPassword: formik.values.newPassword});
        },
    });

    useEffect(() => {
        if(isSuccess) {
            formik.resetForm()
        }
    },[isSuccess])

    return(
        <MainContainer>
            <InnerContainer>
                <Title>Configurações</Title>
                <Content>
                    <PersonalDataContent>
                        <PersonalDataTitle>Dados Pessoais</PersonalDataTitle>
                        {isLoading && (
                            <SkeletonDiv>
                                <ComponentSkeleton maxWidth={1500} minWidth={300} height={56}/>
                                <ComponentSkeleton maxWidth={1500} minWidth={300} height={56}/>
                                <ComponentSkeleton maxWidth={1500} minWidth={300} height={56}/>
                            </SkeletonDiv>
                        )}
                        {isError && !isLoading && (
                            <ErrorMessageContainer>
                                <ErrorMessage>
                                    Erro inesperado ao carregar as informações, por favor tente novamente
                                </ErrorMessage>
                                <RetryButton
                                    text='Tentar Novamente'
                                    onClick={() => refetch()}
                                />
                            </ErrorMessageContainer>
                        )}
                        {!!personalData && (
                            <>
                                <InputData placeholder="Seu nome" value={personalData?.data.name} disabled={true}/>
                                <InputData placeholder="Telefone" value={personalData?.data.phone} disabled={true}/>
                                <InputData placeholder="E-mail" value={personalData?.data.email} disabled={true}/>
                            </>
                        )}
                    </PersonalDataContent>
                    <DivResetPassword>
                        <PersonalDataTitle>Trocar senha</PersonalDataTitle>
                            <DivInput>
                            <InputText
                                inputId="currentPassword"
                                placeholder="Senha atual"
                                type="password"
                                value={formik.values.currentPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                                errorText={formik.errors.currentPassword || ""}
                            />
                            <InputText
                                inputId="newPassword"
                                placeholder="Nova senha"
                                type="password"
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                errorText={formik.errors.newPassword || ""}
                            />
                            <InputText
                                inputId="changePassword"
                                placeholder="Confirmar nova senha"
                                type="password"
                                value={formik.values.changePassword}
                                onChange={formik.handleChange}
                                error={formik.touched.changePassword && Boolean(formik.errors.changePassword)}
                                errorText={formik.errors.changePassword || ""}
                            />
                            </DivInput>
                            <DivButton>
                                <ButtonResetPassword 
                                    text='Trocar senha'
                                    type='submit'
                                    loading={isLoadingChangePassword}
                                    onClick={formik.handleSubmit}
                                    disabled={isLoadingChangePassword}
                                />
                            </DivButton>     
                    </DivResetPassword>
                </Content>
            </InnerContainer>
        </MainContainer>
    )
}