import { DefaultFooter, DivContent, DivImgFooter, InstagramIcon, TextFooter } from "./FooterDefault.styles";


export default function FooterDefault(){
    const handleClickLink = (link: string) => {
        window.open(link)
    }

    return(
        <DefaultFooter>
            <DivContent>
                <TextFooter>
                    Sobre a HiveB, Termos de uso, Política de privacidade, e Proteção à Propriedade Intelectual
                </TextFooter>
                <TextFooter>
                    ©Hivefuse Solucoes Tecnologicas LTDA. Recife, PE
                </TextFooter>   
                <DivImgFooter>
                    <InstagramIcon onClick={() => handleClickLink("https://www.instagram.com/hiveb.oficial/")}/>
                </DivImgFooter>
            </DivContent>
        </DefaultFooter>
    )
}