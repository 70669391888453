
import styled from "styled-components";
import { themes } from "../../themes/themes";
import StandardButton from "../../components/Button/Button";
import { FaCircle, FaLink } from "react-icons/fa";
import Icon from "../../components/Icon/Icon";
import Dropdown from "../../components/Dropdown/Dropdown";

const { bgSitesDashBoard, purple, dashBoardFont, white, blueYourSite, shadow, grayLink, tabletDimension, mobileDimension } = themes;

export const MainContainer = styled.div`
    background-color: ${bgSitesDashBoard};
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const InnerContainer = styled.div`
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (max-width: ${tabletDimension}){
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 96px;
    }
`;

export const NotificationIco = styled.img`
`;

export const YourSitesTitle = styled.h2`
    color: ${purple};
    font-size: 1.5625rem;
    font-family: ${dashBoardFont};
    font-weight: 700;
    line-height: 1.25rem;
    word-wrap: break-word;
    margin-top: 35px;

    @media (max-width: ${tabletDimension}){
        padding: 0 12px;
    }
`;

export const LoadingContainer = styled.div`
    margin: 80px 0;
    display: flex;
    justify-content: center;
`;

export const NovoBotao = styled(StandardButton)`
    max-height: 40px;
    min-width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ErrorMessageContainer = styled.div`
    margin: 24px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const ErrorMessage = styled.h2`
   font-family: ${themes.dashBoardFont};
   text-align: center;
   font-size: 1.25rem;
   color: ${themes.onyx};
   margin-bottom: 24px;
`;

export const RetryButton = styled(StandardButton)`
    max-width: 500px;
`;

export const WebsitePanel = styled.div<{emptyList: boolean}>`
    background-color: ${white};
    border-radius: 25px; 
    padding: 24px; 
    padding-right: 24px; 
    margin-top: 30px;
    flex: 1;
    flex-direction: column;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    gap: 24px;

    ${props => {
        if (props.emptyList) {
            return 'display: flex;'
        }

        return `
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        `;
    }}

    @media (max-width: 1150px){
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: ${tabletDimension}){
        padding: 12px;
        gap: 12px;
        margin-top: 24px;
    }
`;

export const SiteCard = styled.div`
    display: flex; 
    height: fit-content;
    background-color: ${white};
    border-radius: 18px;
    box-shadow: 0px 0px 25px ${shadow};
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:last-child{
        margin-bottom: 24px;
    }
`;

export const SiteName = styled.h1`
    margin: 0 1rem;
    text-align: center;
    font-family: ${dashBoardFont};
    color : ${blueYourSite};
    font-size: 1.6rem; 
    font-weight: 500;
`;

export const StatusSite = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center;
`;

export const PublishmentStatus = styled.h1`
    font-family: ${dashBoardFont};
    color: ${blueYourSite}; 
    font-size: 1rem; 
    font-weight: 500; 
    display: flex;
    justify-content: center; 
    padding-left: 5px;
`;

export const ButtonDiv = styled.div`
    width: 100%;
    max-width: 190px;

    @media (max-width: ${tabletDimension}){
        max-width: 100%;
    }   

    @media (max-width: ${mobileDimension}){
        max-width: 100%;
    }
`;

export const SiteNameUrl = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    width: 100%;
    margin: 12px 0;
    margin-bottom: 6px;
    align-items: center;
`;

export const IconUrl = styled.div`
    display: flex;
    align-items: center;
    max-width: 100%;

    svg {
        flex-shrink: 0 !important;
    }
`;

export const IconLink = styled(FaLink)`
    color: ${grayLink};
    width: 15px;
    height: 15px;
    margin-right: 5px;
`;

export const IconCircle = styled(FaCircle)`
    color: ${({ color }) => color};
    width: 8px !important;
    height: 8px !important;
`;

export const Url = styled.a`
    color: ${blueYourSite};
    font-size: 1rem; 
    font-family: ${dashBoardFont}; 
    font-weight: 500; 
    text-decoration: underline; 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    &:visited {
        color: ${blueYourSite};
    }

    &:hover {
        color:${blueYourSite};
    }

    &:active {
        color: ${blueYourSite};
    }
`;

export const PublishmentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: none;
    width: 100%;
`;

export const Title = styled.h3`
    color: ${themes.black};
    font-size: 1.5rem;
    font-family: ${themes.primaryFont};
    font-weight: 600;
    line-height: 2.063rem;
    word-wrap: break-word;
`;

export const SubTitle = styled.p`
    color: ${themes.darkGray};
    opacity: 0.6;
    font-size: 0.875rem;
    font-family: ${themes.primaryFont};
    font-weight: 500;
    line-height: 1.5rem;
    word-wrap: break-word;
    margin-top: 22px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const Button = styled(StandardButton)`
    margin-top: 38px;
    max-width: 296px;
    height: 100%;
    max-height: 60px;
`;

export const SiteCardSectionText = styled.p`
    font-size: 1rem;
    font-family: ${themes.dashBoardFont};
    color: ${themes.darkCharcoal};
    font-weight: 500;
    padding: 12px;
`;

export const SiteCardSectionIcon = styled(Icon)`
    font-size: 1rem;
    color: ${themes.darkCharcoal};
`;


export const SiteCardSection = styled.a`
    border-bottom: 1px solid ${themes.veryLightShadeOfGray};
    margin: 0 24px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }

    &:last-child{
        border-bottom: none;
        margin-bottom: 12px;
    }
`;

export const Licenses = styled.div`
    display: flex; 
    flex-direction: column ;
    background-color: ${white};
    border-radius: 18px; 
    padding: 16px; 
    margin-bottom: 24px; 
    box-shadow: 0px 0px 25px ${shadow};
    overflow: hidden;
    gap: 10px;
    max-width: 300px;
    @media(max-width: ${themes.mobileDimension}){
       max-width: 100%;
    }
`;

export const AvailableLicenses = styled.p`
    color: ${themes.darkGray};
    font-size: 0.875rem;
    font-family: ${themes.primaryFont};
    font-weight: 500;
    line-height: 1.5rem;
    word-wrap: break-word;
`;

export const ButtonLicenses = styled(StandardButton)`
    width: 100%;
`;

export const NumberLicenses = styled.p`
    color: ${themes.darkGray};
    font-size: 1.875rem;
    font-family: ${themes.primaryFont};
    font-weight: 500;
    line-height: 1.5rem;
    word-wrap: break-word; 
`;

export const DropdownContainer = styled.div`
    display: flex;
    gap: 20px;

    @media(max-width: 330px){
        flex-direction: column;
    }
`;

export const ResponsiveComponent = styled.div`
    @media(max-width: 930px){
        flex: 1;
    }
`;

export const DivSpinner = styled.div`
    margin: 15px 15px 0 20px;
    display: flex;
    justify-content: start;
`;

export const DropdownOptionFilter = styled(Dropdown)``;

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    @media(max-width: 930px){
        flex-direction: column;
    }
`;

export const SkeletonDiv = styled.div`
    margin-bottom: 24px;
`;

export const ErrorMessageContainerLicenses = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px
`;

export const ErrorMessageLicenses = styled.p`
        color: ${themes.darkGray};
        font-size: 0.875rem;
        font-family: ${themes.primaryFont};
        font-weight: 500;
        line-height: 1.5rem;
        word-wrap: break-word;
`;

export const SkeletonDivYourSites = styled.div`
    margin-top: 30px;
`;