import Auth from "../interfaces/authentication/Auth";

export const GetStoredToken = (): Auth | undefined => {
    const recoilPersist = localStorage.getItem("recoil-persist");
    if(recoilPersist != null){
        const parsedData = JSON.parse(recoilPersist);
        return parsedData?.authState as Auth;
    }

    return undefined;
}