import { StyledEngineProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from "./state/authState";
import AxiosConfig from "./configs/AxiosConfig";
import LoginPage from './pages/loginPage/LoginPage';
import DefaultDesign from './components/DefaultDesign/DefaultDesign';
import { ProtectedRoute } from "./helpers/ProtectedRoutesHelper";
import HomePage from "./pages/homePage/HomePage";
import ForgotPasswordPage from "./pages/forgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./pages/resetPasswordPage/ResetPasswordPage";
import DashboardBase from "./pages/DashboardBase/DashboardBase";
import YourSitesPage from "./pages/yourSitesPage/YourSitesPage";
import SettingsPage from "./pages/settingsPage/SettingsPage";
import PaymentList from "./pages/paymentList/PaymentList";

const queryClient = new QueryClient()
  
export default function App() {
  const navigate = useNavigate();
  const [_auth, setAuth] = useRecoilState(authState);
  AxiosConfig.initializeHooks(navigate, setAuth);

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route element={<DefaultDesign/>}>
            <Route path="auth" element={<LoginPage/>}/>
            <Route path="auth/forgot" element={<ForgotPasswordPage/>}/>
            <Route path="auth/recover" element={<ResetPasswordPage/>}/>
          </Route>
          <Route element={<ProtectedRoute/>}>
            <Route element={<DashboardBase/>}>
              <Route path="sites" element={<YourSitesPage/>}/>
              <Route path="settings" element={<SettingsPage/>}/>
              <Route path="payment" element={<PaymentList/>}/>
            </Route>
          </Route>
        </Routes>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}
