import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import {
    Checkbox, 
    FormControlLabel, 
    FormGroup, 
    Typography } from '@mui/material';

import TextInput from '../../components/TextInput/TextInput'; 
import StandardButton from '../../components/Button/Button';
import { useLogin } from '../../hooks/userService/useLogin';
import { 
    DivMain,
    TitleWelcome,
    AlertInstructions,
    DivForgotPasswordRow,
    ForgotPasswordText,
    DivCreateAccount,
    DntHave,
    CreateAccount,
    DivInnerCard,
    TitleLogin,
    Card,
    Form,
    SpacedInput
} from './LoginPage.styles';

export default function LoginPage() {
    const navigate = useNavigate();
    const [searchParams, _setSearchParams] = useSearchParams();
    const [redirectError, setRedirectError] = useState<string | undefined>(undefined);
    const [rememberMe, setRememberMe] = useState(true);
    const rememberMeEnabled = false;
    const createAccountEnabled = true;
    const { mutate: login, isLoading, isError, error: loginError } = useLogin();

    useEffect(() => {
        const redirectError = searchParams.get('redirectError');
        if (!!redirectError && redirectError == 'sessionExpired')
            setRedirectError('Sua sessão expirou');
    }, []);
 
    const validationSchema = yup.object({
        email: yup
          .string()
          .email('Digite um email válido')
          .required('Digite seu email'),
        password: yup
            .string()
            .min(8, 'Sua senha deve possuir mais do que 8 caracteres')
            .required('Digite sua senha')
    });
 
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: () => {
            const submitData = {
                email: formik.values.email, 
                password: formik.values.password
            }
            if (!!redirectError)
                setRedirectError(undefined);
            login(submitData);
        }
    });

    const handleForgotPasswordClick = () => {
        navigate('/auth/forgot');
    }

    const handleCreateSiteClicked = () => {
        navigate('/sites/create');
    }

    return (
        <DivMain>
            <TitleWelcome>
                Seja bem-vindo!
            </TitleWelcome>
            <Card>
                <TitleLogin>
                    Login
                </TitleLogin>
                <DivInnerCard>
                    <Form onSubmit={formik.handleSubmit}>
                        {!isLoading && (!!isError || !!redirectError) && (
                            <AlertInstructions severity="error">{loginError || redirectError}</AlertInstructions>
                        )}
                        <SpacedInput
                            label='E-mail'
                            inputId='email'
                            type='email' 
                            placeholder='E-mail'
                            autocomplete='email'
                            value={formik.values.email} 
                            onChange={formik.handleChange} 
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            errorText={formik.errors.email || ''}
                        />
                        <TextInput 
                            label='Senha'
                            inputId='password'
                            type='password'
                            placeholder='Senha'
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            autocomplete='current-password'
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            errorText={formik.errors.password || ''}
                            />
                        <DivForgotPasswordRow>
                            {rememberMeEnabled && (
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked value={rememberMe} onChange={() => setRememberMe(!rememberMe)} />}
                                        label={<Typography variant="body2">Lembrar</Typography>}
                                    />
                                </FormGroup>
                            )}
                            <ForgotPasswordText onClick={handleForgotPasswordClick}>
                                Esqueci minha senha
                            </ForgotPasswordText>
                        </DivForgotPasswordRow>
                        <StandardButton
                            type="submit"
                            text="Entrar"
                            buttonType= 'primary'
                            loading={isLoading}
                            onClick={formik.handleSubmit}
                        />
                    </Form>
                </DivInnerCard> 
            </Card> 
        </DivMain>
    );
}