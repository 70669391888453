import { Header, LogoHiveB, MenuHamComponent } from "./HeaderMobile.styles";

interface Props{
    handleClickOnOpenMenuButton: () => void;
}

export default function HeaderMobile({handleClickOnOpenMenuButton}: Props){
    return(
        <Header>
            <LogoHiveB src={"https://dev.cdn.hiveb.com.br/assets/studio_logo.png"}/>
            <MenuHamComponent onClick={handleClickOnOpenMenuButton}/>
        </Header>
    )
}