import { Alert } from "@mui/material";
import styled from "styled-components";
import { themes } from "../../themes/themes";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoginCard from "../../components/LoginCard";
import TextInput from "../../components/TextInput/TextInput";

const { purple, darkPurple, secundaryFont, primaryFont, black, white, gray } = themes;

export const DivMain = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 310px;
    width: 100%;
    margin-left: 25px;
    margin-right: 25px;
`;

export const TitleWelcome = styled.h2`
    position: absolute;
    top: -80px;
    color: ${purple};
    font-size: 2.5rem;
    font-family: ${secundaryFont};
    font-weight: 700;
    line-height: 2.813rem;
    word-wrap: break-word;

    @media (max-width: ${themes.mobileDimension}){
        font-size: 2rem;
    }
`;

export const TitleLogin = styled.h3`
    color: ${black};
    font-size: 1.125rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    padding: 25px 0;
    align-self: baseline;
`

export const Container = styled.div`
    height: 100vh;
    width: 100vh;
    background-color: ${purple};
`
export const AlertInstructions = styled(Alert)`
    margin-bottom: 30px;
    text-align: center;
    border-radius: 15px;
    justify-content: center;
`
export const DivInnerCard = styled.div`
    flex-direction: column;
    align-items: center;
    width: 100%;
`
export const DivForgotPasswordRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding-top: 1rem;
    padding-bottom: 1rem;
`;

export const DivCreateAccount = styled.div`
    display: flex;
    justify-content: center; 
    padding-top: 15px;
`
export const DntHave = styled.p`
    color: ${gray};
    font-size: 0.813rem;
    font-family: ${primaryFont};
    font-weight: 500;
    line-height: 1.5rem;
    word-wrap: break-word;
`;

export const CreateAccount = styled.p`
    color: ${darkPurple};
    font-size: 0.813rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    padding-left: 2.5px;
    &:hover{
        opacity: 0.5;
        cursor: pointer;
    }
`

export const ForgotPasswordText = styled.p`
    color: ${darkPurple};
    font-family: ${primaryFont};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    word-wrap: break-word;
    &:hover{
        opacity: 0.5;
        cursor: pointer;
    }
`

export const VisibilityOffImg = styled(VisibilityOff)` 
`

export const VisibilityImg = styled(Visibility)`
`

export const IconButton = styled.div`
    background: none;
    border: none;
    cursor: pointer;
`;

export const Card = styled(LoginCard)`
    width: 100%;
`;

export const Form = styled.form`
`;

export const SpacedInput = styled(TextInput)`
    margin-bottom: 10px;
`;
