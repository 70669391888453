import styled from "styled-components";
import { themes } from "../../themes/themes";
import { FaInstagram } from "react-icons/fa";

const { primaryFont, white, purple } = themes;

export const DefaultFooter = styled.footer`
    background-color: transparent;
    position: relative;
    bottom: 0;
    padding-top: 45px;
    padding-bottom: 25px;
    margin-top: auto;
    margin-right: 12px;
    margin-left: 12px;
`;

export const DivContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
    max-width: 100%;
`;

export const TextFooter = styled.p`
    color: ${purple};
    opacity: 0.70;
    font-size: 0.75rem;
    font-family: ${primaryFont};
    font-weight: 500;
    line-height: 1.125rem;
    text-align: center;
    word-wrap: break-word;
    max-width: 495px;
    margin-top: 6px;
`;

export const DivImgFooter = styled.div`
    display: flex;
    column-gap: 20px;
    margin-top: 6px;
    cursor: pointer;

    img{
        &:hover{
            opacity: 0.7;
        }
    }

    @media (max-width: ${themes.tabletDimension}) {
        z-index: 2;
    }
`;

export const InstagramIcon = styled(FaInstagram)`
    color: ${themes.purple};
    font-size: 24px;
    margin-top: 6px;

    &:hover {
        opacity: 0.5;
    }
`;