import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../components/spinner/spinner";
import SearchInput from "../../components/SearchInput/SearchInput";
import { useGetSitesList } from "../../hooks/siteService/useGetSitesList";
import { 
    MainContainer, 
    InnerContainer, 
    YourSitesTitle, 
    LoadingContainer, 
    ErrorMessage, 
    WebsitePanel,
    Container,
    Title,
    SubTitle,
    Button,
    ErrorMessageContainer,
    RetryButton,
    Licenses,
    NumberLicenses,
    AvailableLicenses,
    ButtonLicenses,
    DropdownContainer,
    ResponsiveComponent,
    DivSpinner,
    SearchContainer,
    DropdownOptionFilter,
    SkeletonDiv,
    ErrorMessageContainerLicenses,
    ErrorMessageLicenses
} from "./YourSitesPage.styles";
import SitesList from "./components/sitesList/SitesList";
import SitesListDrawer from "./components/sitesListDrawer/SitesListDrawer";
import { useGetAvailableLicenses } from "../../hooks/licensedService/useGetAvailableLicenses";
import AvailableLicensesComponent from "../../components/AvailableLicensesComponent/AvailableLicensesComponent";
import ComponentSkeleton from "../../components/Skeleton-Loading/steletonLoading";
import { SiteListItem } from "../../interfaces/payment/GetSitesListResponse";
import PublishmentStatus from "../../interfaces/PublishmentStatusEnum";
import { useInView } from "react-intersection-observer";
import InfiniteScroll from "react-infinite-scroll-component";



export default function YourSitesPage() {
    const [sitesList, setSitesList] = useState<SiteListItem[]>();
    const navigate = useNavigate();
    const { 
        isLoading: isLoadingLicenses, 
        error: errorLicenses, 
        data: dataLicenses, 
        refetch: refetchLicenses } = useGetAvailableLicenses();
    const [isFiltering, setIsFiltering] = useState("");
    const { ref, inView } = useInView();
    const [allSites, setAllSites] = useState<SiteListItem[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [typingTimeOut, setTypingTimeout] =useState<NodeJS.Timeout | null>();
    const [statusPublishment, setStatusPublishment] = useState<PublishmentStatus | undefined>();
    const [statusCreation, setStatusCreation] = useState('');
    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        status,
        refetch,
    } = useGetSitesList(searchValue, statusPublishment, statusCreation);


        const fetchSitesOnChange = (value: string) => {
            setIsFiltering(value)
            if(typingTimeOut) {
                clearTimeout(typingTimeOut);
            }
    
            const timeout = setTimeout(() => {
                if (value !== "") {
                    setSearchValue(value)
                } else {
                    setSearchValue('');
                }
            }, 500)
            
            setTypingTimeout(timeout);
        }

        useEffect(() => {
            refetch()
        },[searchValue, statusPublishment, statusCreation])

        useEffect(() => {
            if (inView && hasNextPage && !fetchSitesOnChange) {
              fetchNextPage();
            }
          }, [inView, fetchNextPage, hasNextPage]);
    
        useEffect(() => {
            if (data) {
                const newData = data.pages.flatMap(page => page.sites)
                setAllSites(newData);
            }
        }, [data]);
    
        useEffect(() => {
            if(isFiltering){
                const filteredSites = allSites.filter(site => 
                    site.name.toLowerCase().includes(isFiltering.toLowerCase())
                );
                setSitesList(filteredSites)
            }else{
                setSitesList(allSites)
            }
        }, [allSites, isFiltering])
    
        const selectValuePublishment = (value: PublishmentStatus) => {
            setStatusPublishment(value);
        }
        
        const selectStatusCreation = (value: string) => {
            setStatusCreation(value)
        }
    

    return(
        <MainContainer>
            <InnerContainer>
            {isLoadingLicenses ? (
                    <SkeletonDiv>
                        <ComponentSkeleton maxWidth={332} minWidth={50} height={152} />
                    </SkeletonDiv>
                ) : (
                    <>
                        {dataLicenses ? (
                            <AvailableLicensesComponent value={dataLicenses.availableLicenses} navigate={navigate} />
                        ) : (
                            <Licenses>
                                {errorLicenses && (
                                    <ErrorMessageContainerLicenses>
                                        <ErrorMessageLicenses>{errorLicenses.message}</ErrorMessageLicenses>
                                        <ButtonLicenses
                                            onClick={() => refetchLicenses()}
                                            buttonType="primary"
                                            text="Tentar novamente"
                                        />
                                    </ErrorMessageContainerLicenses>
                                )}
                            </Licenses>
                        )}
                    </>
                )}  
                <SearchContainer>
                    <SearchInput
                        onChange={(e) => {
                            fetchSitesOnChange(e.target.value)
                        }}
                    />
                    <DropdownContainer>
                        <ResponsiveComponent>
                            <DropdownOptionFilter 
                                    minHeight="60px"
                                    label="Ordenar por:"
                                    initialValue={{value: 'Publicação', text: 'Publicação'}}
                                    options={[
                                        { value: PublishmentStatus.PUBLISHED, text: 'Publicado' },
                                        { value: PublishmentStatus.UNPUBLISHED, text: 'Não Publicado'}
                                    ]}
                                    value={statusPublishment} 
                                    onValueChange={selectValuePublishment}
                                />
                        </ResponsiveComponent>
                        <ResponsiveComponent>
                            <DropdownOptionFilter
                                    minHeight="60px"
                                    label="Ordenar por:"  
                                    initialValue={{value: 'Data de Criação', text: 'Data de Criação'}}
                                    options={[
                                        { value: 'false', text: 'Mais Recentes' },
                                        { value: 'true', text: 'Mais Antigos'}
                                    ]}
                                    value={statusCreation}
                                    onValueChange={selectStatusCreation}
                                />
                        </ResponsiveComponent>
                    </DropdownContainer>
                </SearchContainer> 
                
                <YourSitesTitle>Seus sites</YourSitesTitle>

                {status === "loading" && (
                    <LoadingContainer>
                        <Spinner />
                    </LoadingContainer>
                )}

                {status === "error" && (
                    <WebsitePanel emptyList={true}>
                        <ErrorMessageContainer>
                            <ErrorMessage>
                                {error.message}
                            </ErrorMessage>
                            <RetryButton
                                text='Tentar Novamente'
                                onClick={() => refetch()}
                            />
                        </ErrorMessageContainer>
                    </WebsitePanel>
                )}

                {status === "success" && sitesList && (
                    <InfiniteScroll
                        loader={
                            <LoadingContainer style={{backgroundColor:'white', margin: 0, padding: '80px 0'}}>
                                {hasNextPage && isFetchingNextPage && (
                                    <Spinner/>
                                )}   
                            </LoadingContainer>}
                        dataLength={sitesList.length}
                        next={fetchNextPage}
                        hasMore={!!hasNextPage}
                    >
                        <WebsitePanel emptyList={sitesList.length === 0} >
                            {sitesList.length > 0 && (
                                <SitesList sitesList={sitesList} />
                            )}
                            {sitesList.length === 0 && !isFetchingNextPage && (
                                <Container>
                                    <Title>Ops!</Title>
                                    <SubTitle>Você ainda não possui nenhum site.</SubTitle>
                                    <Button
                                        onClick={() => navigate("/business/create")}
                                        buttonType="primary"
                                        text="Criar site"
                                    />
                                </Container>
                            )}
                        </WebsitePanel>
                    </InfiniteScroll>
                )}
                <SitesListDrawer sitesList={sitesList} />
                <div ref={ref} style={{backgroundColor: 'rgb(226, 227, 243)'}}></div>
            </InnerContainer>
        </MainContainer>
    )
}
