import styled from "styled-components";
import { themes } from "../../themes/themes";
import StandardButton from "../../components/Button/Button";

const { bgSitesDashBoard, purple, dashBoardFont, white, blueYourSite, shadow, grayLink, tabletDimension, mobileDimension, primaryFont } = themes;

export const MainContainer = styled.div`
    background-color: ${bgSitesDashBoard};
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const InnerContainer = styled.div`
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (max-width: ${tabletDimension}){
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 96px;
    }
`;

export const Licenses = styled.div`
    display: flex; 
    flex-direction: column ;
    background-color: ${white};
    border-radius: 18px; 
    padding: 16px; 
    margin-bottom: 24px; 
    box-shadow: 0px 0px 25px ${shadow};
    overflow: hidden;
    gap: 10px;
    max-width: 300px;
    @media(max-width: ${themes.mobileDimension}){
       max-width: 100%;
    }
`;

export const AvailableLicenses = styled.p`
    color: ${themes.darkGray};
    font-size: 0.875rem;
    font-family: ${themes.primaryFont};
    font-weight: 500;
    line-height: 1.5rem;
    word-wrap: break-word;
`;

export const ButtonLicenses = styled(StandardButton)`
    width: 100%;
`;

export const NumberLicenses = styled.p`
    color: ${themes.darkGray};
    font-size: 1.875rem;
    font-family: ${themes.primaryFont};
    font-weight: 500;
    line-height: 1.5rem;
    word-wrap: break-word; 
`;

export const DivSpinner = styled.div`
    margin: 15px 15px 0 20px;
    display: flex;
    justify-content: start;
`;

export const Title = styled.h2`
    color: ${purple};
    font-size: 1.563rem;
    font-family: ${dashBoardFont};
    font-weight: 700;
    line-height: 1.25rem;
    word-wrap: break-word;
`;

export const PersonalDataTitle = styled.h3`
    color: ${purple};
    font-size: 1rem;
    font-family: ${primaryFont};
    font-weight: 600;
    line-height: 1.5rem;
    word-wrap: break-word;
    margin-bottom: 20px;
`;

export const Content = styled.div`
    margin-top: 30px;
`;

export const PaymentPanel = styled.div`
    background-color: ${white};
    border-radius: 25px; 
    padding: 24px; 
    padding-right: 24px; 
    margin-top: 30px;
    flex: 1;
    flex-direction: column;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    display: grid;
    gap: 24px;
    min-height: 380px;

    @media (max-width: ${tabletDimension}){
        padding: 12px;
        gap: 12px;
        margin-top: 24px;
    }
`;

export const SkeletonDiv = styled.div`
    margin-bottom: 24px;
    
`;

export const ErrorMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 22px;
    height: fit-content;
    background-color: ${white};
    border-radius: 18px;
    box-shadow: 0px 0px 25px ${shadow};
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 30px;
    flex-direction: column;
`;

export const ErrorMenssage = styled.p`
        color: ${themes.darkGray};
        font-size: 0.875rem;
        font-family: ${themes.primaryFont};
        font-weight: 500;
        line-height: 1.5rem;
        word-wrap: break-word;
`;

export const ListPanel = styled.div`
    display: flex; 
    height: fit-content;
    background-color: ${white};
    border-radius: 18px;
    box-shadow: 0px 0px 25px ${shadow};
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 30px;
`;

export const ErrorMessage = styled.p`
        color: ${themes.darkGray};
        font-size: 0.875rem;
        font-family: ${themes.primaryFont};
        font-weight: 500;
        line-height: 1.5rem;
        word-wrap: break-word;
    `;

export const ErrorMessageContainerLicenses = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px
`;

export const ErrorMessageLicenses = styled.p`
        color: ${themes.darkGray};
        font-size: 0.875rem;
        font-family: ${themes.primaryFont};
        font-weight: 500;
        line-height: 1.5rem;
        word-wrap: break-word;
`;