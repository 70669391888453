import { useQuery } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";
import { LicensesPaymentList } from "../../interfaces/licensePayment/getLicensePayment";

const unexpectedErrorText = "Não foi possível obter a lista de pagamentos, tente novamente";

const getLicensePayment = async (): Promise<LicensesPaymentList> => {
    try {
        const response = await AxiosConfig
            .getAxiosInstance()
            .get<LicensesPaymentList>('/api/v1/payments/details');

        if (response.status === 200) {

            return response.data;
        }

        throw new Error(unexpectedErrorText);
    } catch (err) {
        throw new Error(unexpectedErrorText);
    }
}

export function useGetLicensePayment() {
    return useQuery<LicensesPaymentList, Error>({
        queryFn: getLicensePayment,
        queryKey: ['getLicensePayment'],
        refetchOnWindowFocus: false
    });
}
