import { ImgLogoHiveBStudio, WrapperCard } from "./style";

interface Props{
    children?: React.ReactNode;
    className?: string;
}

export default function LoginCard({ children, className }: Props){
    return(
        <WrapperCard className={className}>
                <ImgLogoHiveBStudio src={"https://dev.cdn.hiveb.com.br/assets/studio_logo.png"} alt="Logo HiveB Studio em roxo e rosa na parte superior do card"/>
                {children}
        </WrapperCard>
    )
}