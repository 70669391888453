import { useQuery } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";
import { Studio } from "../../interfaces/licensed/getStudio";

const unexpectedErrorText = "Não foi possível obter a quantidade de licenças disponíveis, tente novamente";

const getAvailableLicenses = async () => {
    try{
        const response = await AxiosConfig
            .getAxiosInstance()
            .get<Studio>('/api/v1/studio/details');

        if (response.status === 200)
            return response.data;

        throw new Error(unexpectedErrorText);
    } catch(err){
        throw new Error(unexpectedErrorText);
    }
}


export function useGetAvailableLicenses (){
    return useQuery<Studio, Error>({
      queryFn: getAvailableLicenses,
      queryKey: ['getAvailableLicenses'],
      refetchOnWindowFocus: false  
    });
}