import axios from "axios";
import BaseConfig from "../../configs/BaseConfig";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

const unexpectedErrorText = "Erro inesperado, tente novamente";

const postResetPassword = async (newPassword: string) => {
    try {
        const resetPasswordRequest = {
            resetToken: window.location.search.slice(1).substring(6),
            newPassword: newPassword
        }

        const response = await axios.post(`${BaseConfig.config.businessApiUrl}/api/v1/users/licensed/reset`, resetPasswordRequest);
        if (response.status === 200)
            return response;
        else
            throw new Error(unexpectedErrorText).message;
    } catch(err) {
        throw new Error(unexpectedErrorText).message;
    }
}
        
export const useResetPassword = () => {
    const navigate = useNavigate()
    const mutation = useMutation({
        mutationFn: postResetPassword,
        onSuccess: () => {
            setTimeout(() => navigate("/auth"), 2000)
        }
    });

    return mutation;
}
