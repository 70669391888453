import BaseConfig from "../../configs/BaseConfig";
import { useMutation } from "react-query";
import { Toast } from "../../components/Toast/Toast";
import AxiosConfig from "../../configs/AxiosConfig";

interface ChangePasswordRequest {
    newPassword: string;
    currentPassword: string;
}

const putChangePassword = async (request: ChangePasswordRequest) => {
    try{
        const changePasswordRequest = {
            currentPassword: request.currentPassword,
            newPassword: request.newPassword
        }

        const response = await AxiosConfig
        .getAxiosInstance()
        .put(`${BaseConfig.config.businessApiUrl}/api/v1/users/licensed/change-password`, changePasswordRequest);
        if (response.status === 200) {
            Toast.showToast('Senha alterada com sucesso.','success')
        }
    } catch(err) {
        Toast.showToast('Ocorreu um erro inesperado, por favor tente novamente.', 'error')
    }
}

export const useChangePassword = () => {
    const mutation = useMutation<void, any, ChangePasswordRequest>({
        mutationFn: putChangePassword,
    })
    return mutation; 
}