import { useInfiniteQuery } from "react-query";
import AxiosConfig from "../../configs/AxiosConfig";
import GetSitesListResponse from "../../interfaces/payment/GetSitesListResponse";
import PublishmentStatus from "../../interfaces/PublishmentStatusEnum";

const unexpectedErrorText = "Não foi possível obter a lista de sites, tente novamente";

const getSitesList = async ({ pageParam = 1, name, status, creationTime }: { pageParam: number; name?: string; creationTime?: string, status?: PublishmentStatus}): Promise<GetSitesListResponse> => {
    try {
        const params: any = { page: pageParam };
        if (name) {
            params.name = name;
        }
        if (status) {
            params.status = status;
        }
        if (creationTime) {
            params.creationTime = creationTime;
        }

        const response = await AxiosConfig
            .getAxiosInstance()
            .get<GetSitesListResponse>('/api/v1/sites/get-by-user', { params });

        if (response.status === 200) {
            return response.data;
        }

        throw new Error(unexpectedErrorText);
    } catch (err) {
        throw new Error(unexpectedErrorText);
    }
};

export const useGetSitesList = (name?: string, status?: PublishmentStatus, creationTime?: string) => 
    useInfiniteQuery<GetSitesListResponse, Error>({
        queryFn: ({ pageParam }) => getSitesList({ pageParam, name, status, creationTime }),
        queryKey: ['useGetSitesList', name, status, creationTime],
        getNextPageParam: (lastPage, allPages) => {
            const totalPages = lastPage.totalPage;
            const pagesLoaded = allPages.length;

            if (pagesLoaded < totalPages) {
                return pagesLoaded + 1;
            } else {
                return undefined;
            }
        },
        refetchOnWindowFocus: false
    });