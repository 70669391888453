// Os nomes das cores foram tiradas do site: https://www.color-name.com/
export const themes = {
    purple: "#32358F",
    darkPurple: "#2F2A95",
    purpleNavBar: "#5025BA",
    alertPurpleBg: "#5E42A1",
    alertPurpleBorderBg: "rgba(74, 58, 255, 0.1)",
    alertFontColor: "#6F6C90",
    black: "#000000",
    redError: "#d73b3b",
    green: "green",
    gray: "#9A9A9A",
    darkGray: '#625656',
    bgSitesDashBoard: "#e2e3f3",
    placeholderSearchInputColor: "rgba(80, 37, 186, 0.70)",
    blackShadow: "rgba(0, 0, 0, 0.6)",
    blackWith15PercentOpacity: "rgba(0, 0, 0, 0.15)",
    lightBlue: 'rgba(47, 42, 149, 0.50)',
    lightBrown: 'rgba(161, 154, 154, 1)',
    bgOptionGradient: 'linear-gradient(90deg, rgba(80, 37, 186, 0.27) 0%, rgba(77, 80, 164, 0.08) 100%)',
    white: '#FFFFFF',
    plum: '#E4CCFF',
    veryLightShadeOfGray: '#dddddd',
    whiteOpacity: 'rgba(255, 255, 255, 0.50)',
    magentaBlue: '#363465', 
    mediumGray: '#DCDCDC',
    primaryFont: "'Exo 2'",
    secundaryFont: 'Exo ',
    dashBoardFont: "'DM Sans'",
    alertFont: "Fredoka",
    blueYourSite: '#505298',
    onyx: '#363F3E',
    wenge: '#A19A9A',
    darkCharcoal: '#333333',
    pinkLace: '#F9DCF1', 
    shadow: 'rgba(0, 0, 0, 0.2)', 
    grayLink: '#B3B3B3',
    greenActive: '#299755', 
    redDeactivate: '#D21212',
    tabletDimension: '780px',
    mobileDimension: '450px',
    redExclamation: '#FF0000',
    lightSilver: '#d1d5db',
    yankeesBlue:'#14163A',
    inputShadow: 'rgb(98 86 86 / 57%) 0px 0px 3px 0px',
    inputBorderColor: '#e1e3e6',
    inputLabelBlank: '#7C7C7C',
    closeGray: '#CECECE',
    blueDashBoard: 'rgb(226, 227, 243)',
    robotoFont: "Roboto",
    hover: '#00000010',
    transparentOverlay: '#ffffff00',
    googleBorder: '#757775',
    googleFont: '#1F1F1F'
}