import { ButtonLicenses, Content,  ErrorMenssage,  ErrorMessage,  ErrorMessageContainer,  ErrorMessageContainerLicenses,  ErrorMessageLicenses,  InnerContainer,  Licenses,  ListPanel,  MainContainer,  PaymentPanel, SkeletonDiv, Title } from "./PaymentList.styles";
import PaymentContent from "./components/PaymentContent";
import AvailableLicensesComponent from "../../components/AvailableLicensesComponent/AvailableLicensesComponent";
import ComponentSkeleton from "../../components/Skeleton-Loading/steletonLoading";
import { useGetAvailableLicenses } from "../../hooks/licensedService/useGetAvailableLicenses";
import { useGetLicensePayment } from "../../hooks/paymentService/useGetPaymentLicenses";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default function PaymentList () {
    const {isLoading, data, error, refetch} = useGetAvailableLicenses();
    const {
        isLoading: isLoadingPayments, 
        data: dataPayments, 
        error: errorPayments, 
        refetch: refetchPayments} = useGetLicensePayment();
    
        const navigate = useNavigate();

        return (
        <MainContainer>
            <InnerContainer>
            {isLoading ? (
                    <SkeletonDiv>
                        <ComponentSkeleton maxWidth={332} minWidth={50} height={152} />
                    </SkeletonDiv>
                ) : (
                    <>
                        {data ? (
                            <AvailableLicensesComponent value={data.availableLicenses} navigate={navigate} />
                        ) : (
                            <Licenses>
                                {error && (
                                    <ErrorMessageContainerLicenses>
                                        <ErrorMessageLicenses>{error.message}</ErrorMessageLicenses>
                                        <ButtonLicenses
                                            onClick={() => refetch()}
                                            buttonType="primary"
                                            text="Tentar novamente"
                                        />
                                    </ErrorMessageContainerLicenses>
                                )}
                            </Licenses>
                        )}
                    </>
                )}                
                    <Content>
                        <Title>Pagamentos</Title>
                            <PaymentPanel>
                            {isLoadingPayments && (
                            <SkeletonDiv>
                                <ComponentSkeleton maxWidth={1300} minWidth={50} height={160} />
                            </SkeletonDiv>
                            )}
                            <>
                                {errorPayments && (
                                    <ErrorMessageContainer>
                                        <ErrorMenssage>{errorPayments.message}</ErrorMenssage>
                                        <ButtonLicenses
                                            onClick={() => refetchPayments()}
                                            buttonType="primary"
                                            text="Tentar novamente"
                                        />
                                    </ErrorMessageContainer>
                                )} 
                                {dataPayments?.payments.map((payments, i) => (
                                        <PaymentContent key={i}
                                            placeholderTotalAmount="Valor"
                                            placeholderNumberOfInstallments="Quantidade"
                                            placeholderMetadata="Cartão"
                                            placeholderPaymentCreatedAt="Data"
                                            totalAmount={payments.totalAmount}
                                            metadata={payments.metadata}
                                            numberOfInstallments={payments.numberOfInstallments}
                                            paymentCreatedAt={format(new Date(payments.paymentCreatedAt), 'dd/MM/yyyy ', { locale: ptBR })}
                                        />
                                ))}
                                {dataPayments?.payments.length == 0 && !isLoadingPayments && (
                                    <ErrorMessageContainer>
                                        <ErrorMenssage>Você ainda não realizou nenhuma compra</ErrorMenssage>
                                    </ErrorMessageContainer>
                                )}
                            </>
                            </PaymentPanel>
                    </Content>
            </InnerContainer>
        </MainContainer>
    )
}