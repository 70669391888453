import styled from "@emotion/styled"
import { themes } from "../../../themes/themes";
import { useGetLicensePayment } from "../../../hooks/paymentService/useGetPaymentLicenses";

export default function PaymentContent ({totalAmount, numberOfInstallments, paymentCreatedAt, metadata, placeholderTotalAmount, placeholderNumberOfInstallments, placeholderPaymentCreatedAt, placeholderMetadata}:{
    totalAmount?: number, 
    numberOfInstallments?: number, 
    paymentCreatedAt?: string,  
    placeholderTotalAmount?:string,
    placeholderNumberOfInstallments?: string,
    placeholderPaymentCreatedAt?: string,
    placeholderMetadata?: string,
    metadata: string;
}){

    const {white, tabletDimension, shadow, mobileDimension, darkGray, primaryFont} = themes

    const {error, isLoading, refetch, data} = useGetLicensePayment();

    const ListPanel = styled.div`
        display: flex; 
        height: fit-content;
        background-color: ${white};
        border-radius: 18px;
        box-shadow: 0px 0px 25px ${shadow};
        overflow: hidden;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding: 30px;

        &:last-child{
            margin-bottom: 24px;
        }
    `;

    const ParagraphRight = styled.p`
        align-self: flex-end;
        color: ${darkGray};
        font-size: 0.875rem;
        font-family: ${primaryFont};
        font-weight: 500;
        line-height: 1.5rem;
        overflow-wrap: break-word;
    `;

    const ParagraphLeft = styled.p`
        align-self: start;
        color: ${darkGray};
        font-size: 0.875rem;
        font-family: ${primaryFont};
        font-weight: 500;
        line-height: 1.5rem;
        overflow-wrap: break-word;
    `;

    const Content = styled.div`
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 100px;
        justify-content: space-between;
        gap: 30px;
    `;

    const LabelLeft = styled.label`
        align-self: start;
        color: ${darkGray};
        font-size: 0.875rem;
        font-family: ${primaryFont};
        font-weight: 500;
        line-height: 1.5rem;
        overflow-wrap: break-word;
    `;

    const LabelRight = styled.label`
        align-self: end;
        color: ${darkGray};
        font-size: 0.875rem;
        font-family: ${primaryFont};
        font-weight: 500;
        line-height: 1.5rem;
        overflow-wrap: break-word;   
    `;

    const DivLabelLeft = styled.div`
        display: flex;
        flex-direction: column;
    `;

    const DivLabelRight = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: end;
    `;


    return (
            <ListPanel>
                <Content>
                    <DivLabelLeft>
                        <LabelLeft>{placeholderNumberOfInstallments}</LabelLeft>
                            <ParagraphLeft >{numberOfInstallments}</ParagraphLeft>
                    </DivLabelLeft>
                    <DivLabelLeft>
                        <LabelLeft>{placeholderTotalAmount}</LabelLeft>   
                            <ParagraphLeft>{totalAmount}</ParagraphLeft>
                    </DivLabelLeft>
                </Content>
                <Content>
                    <DivLabelRight>
                        <LabelRight>{placeholderPaymentCreatedAt}</LabelRight>
                            <ParagraphRight>{paymentCreatedAt}</ParagraphRight>
                    </DivLabelRight>
                    <DivLabelRight>
                        <LabelRight>{placeholderMetadata}</LabelRight>
                            <ParagraphRight>
                                {(JSON.parse(metadata).hasOwnProperty("brand") ? JSON.parse (metadata)["brand"] : "" )} 
                                {(JSON.parse(metadata).hasOwnProperty("lastFourDigits") ? (" **** " + JSON.parse(metadata)["lastFourDigits"]) : "" )}
                            </ParagraphRight> 
                    </DivLabelRight>
                </Content>
            </ListPanel>
    )
}